var linkJQuery = 'https://code.jquery.com/jquery-2.2.4.min.js';
function checkJQuery() {
  try {
    $().jquery;
  } catch (e) {
    document.write('<script type="text/javascript" src="' + linkJQuery + '"></script>');
  }
  setTimeout(function(){ 
    jQuery(document).ready(function(){   
      COOKIES_ONLY.init();
    });
  }, 500);
}
checkJQuery();

var COOKIES_ONLY = {
  domainUrl : null,
  languagePrefix : null,

  init: function() {
    let me = this;

    if(jQuery('#modal-config_cookies').length == 0) {
      var languageCode = jQuery('html').attr('lang');
      if (languageCode == undefined) {
        languageCode = jQuery('html').attr('xml:lang');
      }
      me.languagePrefix = languageCode != null ? '/'+languageCode : '/en';
  
      if(jQuery('#z-cookies-container').attr('data-basePath') == 'false') {
        me.languagePrefix = '';
      }
  
      me.domainUrl = jQuery('#z-cookies-container').attr('data-domain');
      if(me.domainUrl) {
        jQuery.ajax({
          type: "GET",
          url: me.domainUrl + me.languagePrefix+"/_ajax/get_cookies_warning",  
          success: function(response){
            let newCode = $(response);          
            // cookies event attach
            newCode.on('click', '[data-action=accept_cookies]', function(){
              me.submitAcceptCookies(jQuery(this));
            });
            newCode.on('click', '[data-action=reject_cookies]', function(){
              me.submitRejectCookies(jQuery(this));
            });
            newCode.on('click', '[data-action=save_cookies]', function(){
              me.submitSaveCookies(jQuery(this));
            });
            jQuery('body').append(newCode);
            if(!me.getCookie('_accept_required_cookies')) {
              jQuery('#z-accept_cookies_message').show();
            }
            jQuery('body').on('click', '[data-modal=config_cookies]', function(){
              me.openModal();
            });
            jQuery('body').on('click', '[data-action=modalClose]', function(){
              me.closeModal();
            });
            jQuery('body').on('click', '[data-action=showDetails]', function(){
              let div = jQuery('#'+jQuery(this).attr('data-target'));
              if(div.is(":visible")) {
                div.hide();
                jQuery(this).find('.z-arrow').css('transform','none');
              } else {
                jQuery(this).find('.z-arrow').css('transform','rotate(90deg)')
                div.show();
              }
              
            });
          }
        });
      } else {
        console.log('need data-domain attribute on #z-cookies-container!');
      }
    }
  },

  openModal: function() {
    let me = this;
    if(me.getCookie('_accept_analytics_cookies') == 1) {
      jQuery('#modal-config_cookies form input[value=_accept_analytics_cookies]').prop('checked',true);
    }

    jQuery('#z-overlay').addClass('overlay-in');
    jQuery('#modal-config_cookies').addClass('md-show').addClass('md-showModal').addClass('modal-in');
    jQuery('#modal-config_cookies button:disabled').attr('disabled',false);
    jQuery('#modal-config_cookies').show();
  },

  closeModal: function() {
    let me = this;
    jQuery('#z-overlay').removeClass('overlay-in');
    jQuery('#modal-config_cookies').removeClass('md-show').removeClass('md-showModal').removeClass('modal-in');
    jQuery('#modal-config_cookies').hide();
  },

  getCookie: function (name) {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');
    if (parts.length === 2) return parts.pop().split(';').shift();
  },

  submitSaveCookies: function(button){
    var me=this;
    button.attr('disabled','true');
    me.closeModal();
		jQuery.ajax({
			type: "POST",
      crossDomain: true,
      xhrFields: {
        withCredentials: true
      },
      url: me.domainUrl + me.languagePrefix+"/_ajax/save_cookies?"+jQuery('#modal-config_cookies form').serialize(),
			success: function(response){
        		$('#z-accept_cookies_message').hide();
			}
		});
	},

  submitAcceptCookies: function(button){
		var me=this;
    jQuery('#modal-config_cookies form input[type=checkbox]').prop('checked',true);
    me.submitSaveCookies(button);
	},
  submitRejectCookies: function(button){
		var me=this;
    jQuery('#modal-config_cookies form input[type=checkbox]').prop('checked',false);
    jQuery('#modal-config_cookies form input[type=checkbox]').first().prop('checked',true);
    me.submitSaveCookies(button);
	}	
}

